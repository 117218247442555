$red: rgba(255, 0, 0, 0.5);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .tabs {
    float: left;
  }

  .button {
    color: white;
    float: right;
    margin-right: 0.5em;
  }
}
.main {
  background: "#192a56";
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;

  .introText {
    //width: "sm";
  }
  img {
    width: 20%;
    margin: 0 auto;
  }
}
.footer {
  position: relative;
  width: 100%;
  background-color: black;
  color: white;

  padding: 1em;
  display: flex;
  justify-content: space-around;

  .div1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    float: center;

    p {
      font-size: 1rem;
    }

    span {
      font-size: 0.75rem;
    }
  }
  .div2 {
    display: flex;
    flex-direction: column;
    text-align: left;
    float: right;
    p {
      font-size: 1rem;
    }

    span {
      font-size: 0.75rem;
    }
  }
}
